.divorce-heading {
    font-family: Marcellus;
    font-size: 32px;
    font-weight: 400;
    line-height: 36.8px;
    border-bottom: 1px solid rgba(174, 174, 174, 21%);
    padding-bottom: 15px;
    padding-left: 50px;
}
.avenue-step1 {
  border-bottom: 3px solid #014921;
  width: 40%;
}
.avenue-step2 {
  border-bottom: 3px solid #014921;
  width: 90%;
}
.avenue-step3 {
  border-bottom: 3px solid #014921;
  width: 100%;
}
.avenue-other-step1 {
  border-bottom: 3px solid #014921;
  width: 70%;
}
.heading-2 {
    font-family: Marcellus;
    font-weight: 400;
    font-size: 38px;
    line-height: 35.11px;
    color: #014921;
}
  .card-text {
    font-family: Marcellus;
    font-size: 28px;
    line-height: 35.11px;
    color: #014921;
  }
  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  .border-l {
    border-radius: 22px 0px 0px 22px;
    border-right: 1px solid rgba(174, 174, 174, 21%);
  }
  .border-r {
    border-radius: 0px 22px 22px 0px;
    border-left: 1px solid rgba(174, 174, 174, 21%);
  }
  .border-c {
    border-left: 1px solid rgba(174, 174, 174, 21%);
  }