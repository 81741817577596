.asset-distribution-planner-heading {
    font-family: Marcellus;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.08px;
    text-align: left;
    color: rgba(1, 73, 33, 1);

}

.asset-distribution-planner-potential {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: rgba(37, 37, 37, 1);


}

.asset-distribution-planner-potential-amount {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: rgba(1, 73, 33, 1);
}

.asset-distribution-planner-paragraph {
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.asset-distribution-planner-percentage {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    /* color: rgba(37, 37, 37, 1); */
    color: rgba(0, 0, 0, 1);
}

.amount {
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
    text-align: left;
    color: rgba(1, 73, 33, 1);

}

.disclaimer-paragraph {
    font-family: Inter;
    font-size: 12px;
    line-height: 14px;
}

.custom-input {
    position: relative;
    margin-bottom: 20px;
}

.custom-input label {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(248, 248, 248, 1);
    padding: 0 5px;
    font-size: 0.85rem;
}

.custom-input .form-control {
    border: 2px solid #ced4da;
    padding: .375rem .75rem;

}

.asset-distribution-planner {
    color: rgba(45, 45, 45, 1);
}

.percentage-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.percentage-container p {
    font-weight: bold;
    font-size: 1rem;
}

.percentage-value {
    font-size: 1.2rem;
}

.distribution-amount {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.settlements-factors .paragraph {
    font-family: Inter;
    font-size: 16.85px;
    font-weight: 400;
    line-height: 20.7px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.settlements-factors-border {
    border-left: 1.5px groove;
    /* Adjust groove and thickness as needed */
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.settlements-factors {
    list-style: none;
    margin-left: 0px;
    align-self: flex-start;
}

.debit-distribution-planner-heading {
    font-family: Marcellus;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.08px;
    text-align: left;
    color: rgba(168, 21, 29, 1);
}

.debit-distribution-planner-potential-amount {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: rgba(168, 21, 29, 1);
}

.custom-input-debit {
    border: 1px solid rgba(168, 21, 29, 1);
    border-radius: 4px;
    width: 132px;
    background-color: rgba(248, 248, 248, 1);
}

.custom-input-credit {
    border: 1px solid rgba(1, 73, 33, 1);
    border-radius: 4px;
    width: 132px;
    background-color: rgba(248, 248, 248, 1);
}

.debit-amount {
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
    text-align: left;
    color: rgba(168, 21, 29, 1);
}

.real-state {
    color: rgba(153, 103, 0, 1);
}

.purchase-input {
    border: 1px solid rgba(153, 103, 0, 1);
    border-radius: 1px;
    height: 34px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: rgba(153, 103, 0, 1);
    background-color: rgba(248, 248, 248, 1)
   
      
}
::placeholder {
    font-family: Inter;
    font-size: 12px;
 
  }

  .realState-subtitle{
    color: black;
    font-family: Inter;
    font-weight: bold;
  }



.purchase-input:focus-visible {
    outline: none;
}

.real-state input {
    border: 1px solid rgba(153, 103, 0, 1);
    border-radius: 1px;
    background-color: rgba(248, 248, 248, 1);
}

.real-state-amount {
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
    text-align: left;
    color: rgba(153, 103, 0, 1);
}

.equity {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
}

.real-state-input-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
    color: rgba(37, 37, 37, 1);
}

.real-state-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    background-color: rgba(248, 248, 248, 1);
}

.real-state-heading {
    font-family: Marcellus;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.08px;
    text-align: left;
    color: rgba(153, 103, 0, 1);
}

.debt .custom-input label {
    left: 59px;
    font-size: 14px;
    color: #666;
}

.custom-input .real-state-input-label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
}

.custom-input .width {
    width: 190px;
    text-align: center
}

.real-state .custom-input .real-state-input {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: rgba(153, 103, 0, 1);
}

.real-state .custom-input .real-state-input:focus-visible {
    outline: none;
}

.option1Input {
    width: 54px;
    height: 24px;
}

.option2Input {
    height: 24px;
    width: 90px;
}

.real-state .custom-input .sperateSpouse {
    top: -26px;
}

.option2Lablewidth {
    width: 200px;
}

.option-heading {
    font-family: Marcellus;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(153, 103, 0, 1);
}

.option-paragraph {
    font-family: Inter;
    font-size: 16.27px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}