:root {
  --green: #014921;
  --white: #FFFFFF;
}

.mainContainerBg {
  background-color: red;
}

.auth-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 51px;
  height: 51px;
  margin-left: 32px;
  background: linear-gradient(135deg, #DAEEDC 100%, #F8FAF5 50%);
  border-radius: 50%;
}

.home .content {
  background-image: url('/public/ellipse.png');
  background-position: left top;
  background-repeat: no-repeat;
  padding-left: 30px;
}
.onboard-background {
  background-image: url('/public/assets//img/Onboarding-1.png');
  width: 100%;
  height: 100vh;
  background-repeat: round;
}


.btn-primary {
  min-width: 100px;
  Height: 50px;
  border-radius: 65px;
  color: var(--white);
  background-color: var(--green);
  border: none;
}

.btn-primary:hover {
  color: var(--white);
  background-color: var(--green);
}

.btn.btn-secondary {
  width: 130px;
  height: 50px;
  border-radius: 53px;
  border: none;
  background-color: #D3EEE9;
  color: #349280;
}

.bg-light-green {
  background-color: #D5EFEB;
}

.bg-silver-gray {
  background-color: rgba(174, 174, 174, 21%);
}

.border-rounded-5 {
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.heading-4 {
  font-family: Marcellus;
  font-weight: 400;
  font-size: 28px;
  line-height: 35.11px;
  color: #014921;
}

.mt-17 {
  margin-top: 17px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-32px {
  margin-top: 32px;
}

.mt-29 {
  margin-top: 29px;
}


.mt-23 {
  margin-top: 23px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-27 {
  margin-bottom: 27px;
}

.mt-44 {
  margin-top: 44px;

}

.mt-72 {
  margin-top: 72px;
}

.mb-23 {
  margin-bottom: 23px;
}

.paragraph {
  font-weight: 400px;
  font-size: 16px;
  line-height: 19.36px
}

.card-shadow {
  box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 15%);
}

.card-shadow .paragraph {
  font-weight: 400px;
  font-size: 16px;
  line-height: 19.36px;
  margin-top: 13px
}


/*  */
/* Montly Component Styling start */
.border {
  border: 1px solid #0149214D
}

.fas-bg {
  width: 100px;
  height: 100px;
  border-radius: 85px;
  background-color: #0149210D;
  padding: 32px;

}

.pt-40 {
  padding-top: 40px;
}

.expenses-bg {
  background: #F4F0E30D;
}

.expenses-Paragraph {
  color: #656565;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 16.94px;
  justify-content: space-around;
  text-align: start;
  max-width: 640px
}

.mt-13 {
  margin-top: 13px;
}

.mt-24 {
  margin-top: 24px;
}


.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-153 {
  margin-top: 153px
}

input[readonly] {
  background-color: rgba(248, 248, 248, 1);
}

.input-styling {
  width: 158px;
  height: 47px;
  border-radius: 3.77px;
  border: 0.5px solid #B6B6B6;
  background-color: #FFFFFF;
  color: #014921;
  font-size: 14px;
  font-weight: 400;
  line-height: 47px;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.input-styling-total {
  width: 185px;
  height: 50px;
  border-radius: 3.77px;
  border: 0.5px solid #B6B6B6;
  background-color: #cbc8c8;
  color: #014921;
  text-align: center;
  outline: none;
}

.input-styling-total:focus {
  border: none
}

.input-margin-right {
  margin-right: 70px;
}

@media (max-width: 768px) {
  .input-margin-right {
    margin-right: 0px;
  }
}

.border-buttom {
  border-bottom: 1.02px solid #0000001A;
}

.mt-21 {
  margin-top: 21px;
}

.total {
  font-weight: 700;
  font-size: 16px;
  color: var(--green);
  line-Height: '19.78px'
}

.pt-30 {
  padding-top: 30px;
}

.mt-104 {
  margin-top: 104px;
}

.pb-44 {
  padding-bottom: 44px;
}

.btn.btn-secondary {
  background-color: #D3EEE9;
  color: #014921;
  border: 1.03px solid #349280;
}

.btn-size {
  width: 130px;
  height: 50px;
  border-radius: 53px;
}

@media (max-width: 360px) {
  .btn-size {
    width: 50px;
    height: 20px;
    border-radius: 30px;
  }

  .mb-sm-10 {
    margin-bottom: 10px;
  }
}

@media (max-width: 780px) {
  .mb-sm-10 {
    margin-bottom: 10px;
  }
}

.fa-chevron-left {
  opacity: 0.5;
}

.ml-52 {
  margin-left: 52px;
}

.mt-22 {
  margin-top: 22px;
}

.settlement .box {
  border: 1px solid rgba(24, 42, 180, 0.1);
  background: rgba(248, 248, 248, 1);
  border-radius: 12px;
  min-height: 437px;
}

.settlement h4 {
  font-family: Marcellus;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.08px;
  text-align: left;
  color: #014921
}

.settlement .disclaimer {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 15px;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.bg-green {
  background-color: #014921;
}

.paragraph-term-condition {
  font-size: 12px;
}

.modal-text{
  font-size: 12px;
}

.modal-content {
  height: 70vh;
}

.modal-body {
  overflow-y: auto;
}

.modal-bg {
  backdrop-filter: 1px;
  background-color: #00000038;
}