.white-bar {
    font-family: Marcellus;
    font-size: 24px;
    font-weight: 400;
    line-height: 30.09px;
    text-align: left;
    color: #014921;
}
.bar {
    font-family: Marcellus;
    font-size: 20.43px;
    font-weight: 400;
    line-height: 23.49px;
    color: #FFFFFF;
    background-color: #014921;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
}
.box-heading {
    font-family: Marcellus;
    font-size: 32px;
    font-weight: 400;
    line-height: 36.8px;
    color: #014921;
}
.box-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #656565;
}
.box-text span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    color: #656565;
}
.account-typo {
    font-family: Marcellus;
    font-size: 16.34px;
    font-weight: 400;
    line-height: 20.49px;
    color: #262626;
}
.box {
    border-bottom: 1.02px solid;
    border-color: #0000001A;
    border-radius: 5.11px;
}
.asset-label {
    font-family: Inter;
    font-size: 16.34px;
    font-weight: 600;
    line-height: 19.78px;
    color: #484848;
    margin-top: 36px;
    margin-bottom: 14px;
}
.asset-input {
    border-radius: 3.77px 0px 0px 0px;
    border: 0.5px 0px 0px 0px;
}
.asset-input-total {
    width: 185px;
    height: 50px;
    border-radius: 3.77px;
    border: 0.5px solid #B6B6B6;
    background-color: #cbc8c8;
    color: #014921;
    text-align: center;
    outline: none;
}
.asset-input-total:focus {
    border: none !important
}
.arrow {
    font-family: Inter;
    font-size: 18.95px;
    font-weight: 400;
    line-height: 22.93px;
    color: #000000;
    opacity: 50%
}