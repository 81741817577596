.onboard-heading {
    font-family: Marcellus;
    font-size: 32px;
    font-weight: 400;
    line-height: 36.8px;
    border-bottom: 1px solid #AEAEAE;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-top: 15px;
}

.heading-2 {
    font-family: Marcellus;
    font-weight: 400;
    font-size: 38px;
    line-height: 35.11px;
    color: #014921;
}

  .card-text {
    font-family: Marcellus;
    font-size: 30px;
    line-height: 35.11px;
    color: #014921;
  }
  .heading-other-1 {
    font-family: Marcellus;
    font-size: 45px;
    line-height: 35.11px;
    color: #014921;
  }
  .other-text {
    font-family: Inter;
    font-size: 30px;
    font-weight: 400;
    line-height: 35.11px;
    color: rgba(45, 43, 43, 100%)
  }