.onboard-heading {
  font-family: Marcellus;
  font-size: 32px;
  font-weight: 400;
  line-height: 36.8px;
  border-bottom: 1px solid #AEAEAE;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-top: 15px;
}

.heading-2 {
  font-family: Marcellus;
  font-weight: 400;
  font-size: 38px;
  line-height: 35.11px;
  color: #014921;
}

.trial-plan-headeing {
  font-family: Inter;
  font-weight: 400;
  font-size: 36px;
  line-height: 35.11px;
  color: #000000;
}

.card-text {
  font-family: Marcellus;
  font-size: 28px;
  line-height: 35.11px;
  color: #014921;
}

/* ///////////// */
.cardTitle {
  font-family: Marcellus;
  font-size: 24px;
  font-weight: 400;
  line-height: 34.8px;
  text-align: left;
  color: #014921;
}

.line-27 {
  padding-left: 20%;
  padding-right: 20%;
  opacity: 0.4;
  border: 1px solid #0000004D;
}

.paymentCard {
  width: 550px;
  box-shadow: 0px 3px 9px 0px #00000040;
  background: #FFFFFF;
}

.cardHeading {
  font-family: Marcellus;
  font-size: 40px;
  font-weight: 400;
  line-height: 58px;
  color: #014921;
}

.cardContent {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #484848;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .paymentCard {
    width: 510px;
  }

  .line-27 {
    width: 440px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .paymentCard {
    width: 300px;
  }

  .line-27 {
    width: 243px;
  }
}

.list-icon {
  top: 0px;
  position: absolute;
}