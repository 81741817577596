.onboard-heading {
    font-family: Marcellus;
    font-size: 32px;
    font-weight: 400;
    line-height: 36.8px;
    border-bottom: 1px solid #AEAEAE;
    padding-bottom: 15px;
    padding-left: 50px;
}

.avenue-onboard {
  border-bottom: 3px solid #014921;
  width: 15%;
}

.heading-2 {
    font-family: Marcellus;
    font-weight: 400;
    font-size: 38px;
    line-height: 35.11px;
    color: #014921;
}

.heading-3 {
    font-family: Inter;
    font-weight: 600;
    font-size: 28px;
    line-height: 35.11px;
    color: #014921;
  }
  .card-text {
    font-family: Marcellus;
    font-size: 28px;
    line-height: 35.11px;
    color: #014921;
  }