.color-anew {
  color: rgba(26, 26, 26, 0.9);
}

.anew-dim {
  color: rgba(26, 26, 26, 0.6);
}
.blue-color {
  color: rgb(0, 116, 216) !important;
}

.anew-h {
  font-size: 14px;
  font-weight: 500;
}
.anew-price {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: end;
  width: 100%;
}
.anew-price2 {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: end;
  width: 100%;
}
.anew-p {
  font-size: 12px;
  font-weight: 400;
}
.anew-h1 {
  font-size: 16px;
  font-weight: 500;
}
.anew-price-title {
  font-size: 14px;
  font-weight: 500;
}
.price-h1 {
  font-size: 36px;
  font-weight: 600;
}
.price-border {
  border: 0.5px solid rgba(26, 26, 26, 0.6);
  margin-left: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.border-line {
  border-bottom: 0.1px solid #1a1a1ae6;
}

.payment-main-h {
  font-weight: 500;
  font-size: 20px;
}
.labels-h {
  font-weight: 500;
  color: rgba(26, 26, 26, 0.7);
  font-size: 13px;
}

/* Stripe Design */
.CardNumberElement {
  /* Add your styles here */
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #495057;
}

/* Optionally, you can style the invalid state */
.CardNumberElement.StripeElement--invalid {
  border-color: #dc3545;
}

/* Optionally, you can style the focus state */
.CardNumberElement.StripeElement--focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Style for CardExpiryElement */
.CardExpiryElement {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #495057;
}

/* Optionally, style for invalid state */
.CardExpiryElement.StripeElement--invalid {
  border-color: #dc3545;
}

/* Style for CardCvcElement */
.CardCvcElement {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #495057;
}

/* Optionally, style for invalid state */
.CardCvcElement.StripeElement--invalid {
  border-color: #dc3545;
}

.stripe-pay-btn {
  background-color: #014921 !important;
  color: #ffffff !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: "none";
  background-color: #014921 !important;
  color: #ffffff !important;
}

/* .stripe-adress-component {
  display: none;
} */