.sidebar {
  background-color: #014921;
  border-right: 1px solid #dee2e6;
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
  width: 300px;
}

.sidebar .sidebar-heading {
  font-size: 1.1rem;
}

.sidebar .nav-link {
  color: white;
  font-weight: bold;
}

.sidebar .nav-link:hover {
  color: white;
  font-weight: bold;
}

.sidebar .nav-pills{
  margin-top: 80px;
}

.sidebar .nav-pills .nav-link {
  background-color: transparent;
  color: white;
  font-family: Marcellus;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.06px;
  text-align: left;
}

.dropdown-container .nav-link {
  font-family: Inter !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
}

.sidebar .bellIconColor {
  color: #c9c8d2;
}

.sidebar .name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: white;
  margin-top: 16px;
  margin-bottom: 0px;
}

.sidebar .image {
  margin-top: 42px;
}

.sidebar .button {
  border: #dee2e6 1px solid;
  height: 30px;
  width: 58px;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.sidebar .listcontainer {
  margin-block: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 130px;
}

.sidebar .subnav_item {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 20.06px;
  text-align: left;
  color: white;
  text-decoration: none;
  
  
}