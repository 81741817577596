.heading {
    font-family: Marcellus;
    font-size: 32px;
    font-weight: 400;
    line-height: 36.8px;
    color: #014921;
}

.paragraph {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #656565;
}

.paragraph a {
    color: #656565;
}

.input-label {
    font-family: Inter;
    font-size: 16.34px;
    font-weight: 600;
    line-height: 19.78px;
}



.input-row {
    border-bottom: 1.02px solid #0000001A
}

.mt-116{
    margin-top: 116px;
}